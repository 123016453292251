import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="NFT Marketplace Website Template | Uizard"
    category="Website Templates"
    url="/templates/website-templates/nft-marketplace-website/"
    metaDescription="Looking to design your own NFT marketplace website? NFTrade, our website template, is here to kickstart your project. Sign up for Uizard Pro today!"
    description="
    h2:NFTrade - an NFT marketplace website design template
    <br/>
    Looking to design your own NFT marketplace website? NFTrade, our <a:https://uizard.io/templates/website-templates/>website design template</a>, is here to kickstart your project. Packed with all the screens and user flows that your app mockup could need, NFTrade is ready to go straight out of the box.
    <br/>
    h3:Trade slow design for rapid app prototyping
    <br/>
    Tired of slow, cumbersome <a:https://uizard.io/ux-design/>UX design tools</a>? Uizard is the perfect solution for you. With our AI-powered features, you could transform your NFT marketplace website idea from vision to functional prototype in next to no time.
    <br/>
    h3:Create unique & stylish website mockups every time
    <br/>
    Customization is also super easy with Uizard. Start from scratch or update one of our <a:https://uizard.io/templates/>UI templates</a> to suit your brand vision and goals. With Uizard, you can add new screens, import images, update user journeys, and apply your design aesthetic with ease. Get started on your NFT marketplace website design with Uizard Pro today.
    "
    pages={[
      "An intuitive home page screen with clear CTAs",
      "A wallet connection screen to demonstrate the payment mechanism",
      "NFT browsing screen with images of NFTs for sale",
      "NFT display pages with auction details",
    ]}
    projectCode="gWRdyyZ0MxcMx9bGOJQG"
    img1={data.image1.childImageSharp}
    img1alt="nft marketplace website design template cover screen"
    img2={data.image2.childImageSharp}
    img2alt="nft marketplace website design template trading screens"
    img3={data.image3.childImageSharp}
    img3alt="nft marketplace website design template product screen"
    img4={data.image4.childImageSharp}
    img4alt="nft marketplace website design template user profile screen"
    img5={data.image5.childImageSharp}
    img5alt="nft marketplace website design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/nft-marketplace-website/nft-market-website-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/nft-marketplace-website/nft-market-website-screens.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/nft-marketplace-website/nft-market-website-product.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/nft-marketplace-website/nft-market-website-profile.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/nft-marketplace-website/nft-market-website-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
